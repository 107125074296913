/* Resets */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body,
#root {
  font-size: 16px;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: #fff;
}

#root {
  display: flex;
  flex-direction: column;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  40% {
    transform: translate3d(0, -10%, 0) scale(1.2);
  }
  100% {
    transform: translate3d(0, -20%, 0) scale(1.5);
  }
}

.jump {
  transform-origin: 50% 0%;
  animation: jump 0.3s linear alternate infinite;
}

.inline-text {
  display: inline-flex;
}

.space-customfield {
  padding-right: 10px;
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
